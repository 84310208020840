import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"

const ArticleCard = ({ article }) => {
  // console.log("Article Card: ", article)
  return (
    <div 
      className="pooche-card" 
      style={{ padding: "8px" }}
    >
      <div
        className="uk-card uk-card-default uk-card-small uk-border-rounded"
        style={{ height: "100%" }}
      >
        <div className="uk-card-media-top">
          <Link to={`/article/${article.slug}`}>
            {article?.cover && (
              <GatsbyImage
                image={getImage(article.cover.localFile)}
                alt={article.headings.title}
                title={article.headings.title}
                data-uk-img="target: !.uk-slideshow-items"
                style={{ aspectRatio: "1.57"}}
              />
            )}
          </Link>
        </div>
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" data-uk-grid="true">
            <div className="uk-width-auto">
              {article?.author?.avatar && (
                <GatsbyImage
                  image={getImage(article.author.avatar.localFile)}
                  alt={article.headings.title}
                  title={article.headings.title}
                  className="uk-border-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              )}
            </div>
            <div className="uk-width-expand">
              <span>
                <h6
                  className="uk-margin-remove-bottom uk-text-bold"
                  style={{ color: "#687169" }}
                >
                  {article.author.name}
                </h6>
              </span>
              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                <Moment format="MMMM Do YYYY">{article.publishedAt}</Moment>
              </p>
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          <h5 className="uk-margin-small-bottom uk-text-bold">
            {article.cardTitle}
          </h5>
          <p className="uk-text-small uk-text-muted">{article.shortDesc}</p>
          <Link
            //className="uk-button uk-button-default uk-button-small uk-margin-small-bottom uk-text-small"
            data-uk-icon="arrow-right"
            //style={{ color: "#687169", borderColor: "red", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
            className="hover-underline-animation-dark"
                style={{ color: "#687169", textDecoration: "none" }}
            to={`/article/${article.slug}`}
          >
            <span className="uk-margin-small-right uk-text-capitalize" style={{ fontWeight: "600" }}>
              Read more
            </span>
            
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
