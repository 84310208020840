import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import Moment from "react-moment"

const serviceCard = serviceCard  => {
  //console.log("serviceCard: ", serviceCard)
  //console.log("Title: ", serviceCard.title)

  return (
    <div className="pooche-card" style={{ padding: "10px" }}>
      <div
        className="uk-card uk-card-default uk-card-small uk-border-rounded"
        style={{ height: "100%" }}
      >
        <div className="uk-card-media-top">
            <Link to={`/services/${serviceCard.slug}`}>
                {serviceCard.image && (
                <GatsbyImage
                    image={getImage(serviceCard.image.localFile)}
                    alt={serviceCard.title}
                    title={serviceCard.title}
                    data-uk-img="target: !.uk-slideshow-items"
                />
                )}
            </Link>
        </div>
        <div className="uk-card-header" style={{ backgroundColor: "#D0D9CD", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px" }}>
          <div className="uk-grid-small uk-flex-middle" data-uk-grid="true">
            <div className="uk-width-auto">
              {/* service?.author?.avatar && (
                <GatsbyImage
                  image={getImage(service.author.avatar.localFile)}
                  alt={service.headings.title}
                  title={service.headings.title}
                  className="uk-border-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              ) */}
            </div>
            <div className="uk-width-expand" style={{ backgroundColor: "#D0D9CD" }}>
              <span>
                <h6
                  className="uk-margin-remove-bottom uk-text-bold"
                  style={{ color: "#687169" }}
                >
                    { serviceCard.title }
                  </h6>
              </span>
              {/* <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                <Moment format="MMMM Do YYYY">{ service.publishedAt }</Moment>
              </p> */}
            </div>
          </div>
        </div>
        {/* <div className="uk-card-body">
          <h5 className="uk-margin-small-bottom uk-text-bold">
            {service.cardTitle}
          </h5>
          <p className="uk-text-small uk-text-muted">{service.shortDesc}</p>
          <Link
            className="hover-underline-animation-dark"
            style={{ color: "#687169", textDecoration: "none" }}
            to={`/service/${service.slug}`}
          >
            READ MORE
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default serviceCard
