import React from "react"
import Slider from "react-slick"
import ArticleCard from "./article-card"

const ArticleSlider = ({ articles }) => {
  //console.log("Articles: ", articles)

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          //initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {articles.map((article, index) => {
        //console.log("Article: ", article)
        return <ArticleCard key={index} article={article} />
      })}
    </Slider>
  )
}

export default ArticleSlider
