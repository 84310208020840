import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Headings from "../components/headings"
import CtaSlider from "../components/cta-slider"
import ServiceGrid from "../components/service-grid"
import ArticleSlider from "../components/article-slider"

const ServicesPage = () => {
    const { strapiServicePage, allStrapiServiceCard } = useStaticQuery(graphql`
      query {
        strapiServicePage {
          hero {
              ...StrapiHero
          }
          headings {
              title
              description
          }
          articleSlider {
            articles {
              ...StrapiArticles
            }
          }
          callToAction {
            ...StrapiCallToAction
          }
          seo {
            ...StrapiSeo
            metaSocial {
              socialNetwork
              title
              description
            }
            structuredData {
              _type
              name
              url
              contactPoint {
                _type
                availableLanguage
                contactType
                telephone
              }
              sameAs
            }
          }    
        }
        allStrapiServiceCard(sort: {fields: seq, order: ASC}) {
          nodes {
            title
            slug
            dataColor
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1.5
                  )
                }
              }
            }  
          }
        }        
      }
  `)

  //console.log("strapiServicePage: ", strapiServicePage)

  const { hero, headings, articleSlider, callToAction } = strapiServicePage

  const articles = articleSlider.articles

  const callToActionSections = callToAction.call_to_action_sections
  //console.log("strapiHome callToActionSections: ", callToActionSections)

  const serviceCards = allStrapiServiceCard.nodes

  const structuredData = strapiServicePage.seo.structuredData

  const imageUrl = `${strapiServicePage.seo.metaImage.localFile.publicURL}`

  //console.log("strapiServicePage - imageUrl: ", imageUrl)

 
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>
      <Seo
        title={strapiServicePage.seo.metaTitle}
        description={strapiServicePage.seo.metaDescription}
        image={imageUrl}
        post={false}
        metaSocial={strapiServicePage.seo.metaSocial}
        metaRobots={strapiServicePage.seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings title={headings.title} description={headings.description} />
          <div style={{ marginTop: "20px"}}>
            <ServiceGrid serviceCards={serviceCards} /> 
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove ">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="Featured Articles"
            description="Checkout some of our articles from James Walter Raymond Ltd"
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
          <ArticleSlider articles={articles} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesPage
